import {
  AcademicCapIcon,
  BellIcon,
  BookmarkIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { NavItemType } from "./NavItem";

export const NAVIGATION_ITEMS: NavItemType[][] = [
  [
    {
      name: "Dashboard",
      href: "/",
      icon: HomeIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "FarmRaise Tracks",
      href: "/tracks",
      icon: CreditCardIcon,
      premiumOnly: true,
      partnerAccess: true,
      beta: false,
      new: false,
    },
  ],
  [
    {
      name: "Funding Alerts",
      href: "/programs/alerts",
      icon: BellIcon,
      premiumOnly: true,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "Funding Programs",
      href: "/programs",
      icon: CurrencyDollarIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "Saved Programs",
      href: "/programs/saved",
      icon: BookmarkIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
  ],
  [
    {
      name: "EQIP Application",
      href: "/programs/eqip",
      icon: ClipboardDocumentListIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "FSA Records",
      href: "/module/fsa-records/start",
      icon: FolderOpenIcon,
      premiumOnly: true,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "FSA Educational Hub",
      href: "https://www.farmraise.com/usda-fsa",
      icon: AcademicCapIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
  ],
  [
    {
      name: "Videos",
      href: "/videos",
      icon: VideoCameraIcon,
      premiumOnly: true,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "Help Center",
      href: "/help",
      icon: QuestionMarkCircleIcon,
      premiumOnly: false,
      partnerAccess: false,
      beta: false,
      new: false,
    },
    {
      name: "Chat With Us",
      href: "/chat",
      icon: UsersIcon,
      premiumOnly: false,
      beta: false,
      partnerAccess: false,
      new: false,
    },
  ],
];
