import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { FC, Fragment } from "react";
import { EntityPicker } from "~/components/dashboard/entity-picker/EntityPicker";
import { LogoLink } from "../LogoLink";
import { NavItem, NavItemType } from "./NavItem";
import { NAVIGATION_ITEMS } from "./navigationItems";

interface MobileLeftNavBarProps {
  currentNavItem?: NavItemType;
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  useProgramsSidebar: boolean;
}

export const MobileLeftNavBar: FC<MobileLeftNavBarProps> = ({
  currentNavItem,
  sidebarOpen,
  setSidebarOpen,
  useProgramsSidebar,
}) => {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-40 flex ${
          useProgramsSidebar ? "lg:hidden" : "md:hidden"
        }`}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex flex-shrink-0 items-center px-4">
              <LogoLink style="FullLogo" />
            </div>
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="mt-4 border-t border-borderGrey">
                <EntityPicker />
              </div>
              <nav className="flex-1 divide-y divide-borderGrey px-4 pb-4">
                {NAVIGATION_ITEMS.map((navGroup, index) => {
                  return (
                    <div key={index} className="space-y-2 py-4">
                      {navGroup.map((item) => (
                        <div key={item.name}>
                          <NavItem
                            key={item.name}
                            item={item}
                            currentNavItem={currentNavItem}
                          />
                        </div>
                      ))}
                    </div>
                  );
                })}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};
