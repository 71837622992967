import { FC } from "react";
import { EntityPicker } from "~/components/dashboard/entity-picker/EntityPicker";
import { NAVIGATION_ITEMS } from "./navigationItems";
import { NavItem, NavItemType } from "./NavItem";

interface DesktopLeftNavBarProps {
  currentNavItem?: NavItemType;
}

export const DesktopLeftNavBar: FC<DesktopLeftNavBarProps> = ({
  currentNavItem,
}) => {
  return (
    <div className="flex flex-grow flex-col overflow-y-auto border-r border-borderGrey bg-white">
      <div className="flex flex-grow flex-col">
        <div className="">
          <EntityPicker />
        </div>
        <nav className="flex-1 divide-y divide-borderGrey px-4 pb-4">
          {NAVIGATION_ITEMS.map((navGroup, index) => {
            return (
              <div key={index} className="space-y-3 py-6">
                {navGroup.map((item) => (
                  <div key={item.name}>
                    <NavItem
                      key={item.name}
                      item={item}
                      currentNavItem={currentNavItem}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </nav>
      </div>
    </div>
  );
};
